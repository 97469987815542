.state-list {
    position: relative;
    margin-bottom: rem-calc(48);
    overflow-x: auto;
    // sass-lint:disable no-vendor-prefixes
    -webkit-overflow-scrolling: touch;
    // sass-lint:enable no-vendor-prefixes
}

.state-list__table {
    tr {
        border-top: 1px solid rgba($sbv-grey, .2);
        border-bottom: 1px solid rgba($sbv-grey, .2);

        td:nth-child(1) {
            width: rem-calc(24);
        }

        td:nth-child(2) {
            max-width: 250px;
            min-width: 150px;
        }

        td:nth-child(3) {
            width: auto;
            text-align: left;
        }

        td:nth-child(4) {
            width: auto;
            text-align: right;
            padding-left: rem-calc(24);
        }

        td:nth-child(5) {
            width: auto;
            text-align: right;
            padding-left: rem-calc(24);
        }
    }

    td {
        position: relative;
        padding: rem-calc(8) 0;
        white-space: nowrap;
        vertical-align: middle;
    }

    .large-text {
        font-weight: bold;
    }

    .small-text {
        font-family: $label-font;
        font-size: rem-calc(12);

        [class*='icon-'] {
            margin-right: rem-calc(8);
            vertical-align: middle;
            font-size: rem-calc(14);
        }

        // This icon is quite small compared to others.
        // Fix this special case.
        .icon-user {
            font-size: rem-calc(18);
        }
    }

    .prefix {
        display: inline-block;
        margin-right: rem-calc(8);
        width: rem-calc(36);
        text-align: center;
        font-size: rem-calc(18);
        font-weight: bold;
        vertical-align: middle;

        &[class*='icon-'] {
            font-size: rem-calc(14);
        }
    }
}

.state-list__no-data,
.state-list__loader {
    padding: rem-calc(16) rem-calc(20) rem-calc(16) 0;
}

.state-list__loader {
    .loader {
        margin-left: rem-calc(20);
        vertical-align: middle;
        display: inline-block;
    }
}