.preview {
    .add-more {
        .icon-plus {
            bottom: initial;
        }
    }
}

.file-upload-tooltip {
    display: none;
}