.detail-view {
    > .title {
        .icon-list {
            display: block;
            margin-top: rem-calc(10);
            overflow: hidden;

            > * {
                display: inline-block;
                vertical-align: middle;

                &:not(:last-child) {
                    margin-right: rem-calc(8);
                }
            }

            @include breakpoint(medium) {
                display: inline-block;
                margin-top: rem-calc(0);
                margin-left: rem-calc(24);
                vertical-align: -20%;
            }
        }
    }
}
