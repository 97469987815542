.state-buttons {
    position: relative;
    display: flex;
    max-width: rem-calc(400);

    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }

    .state-buttons__button:not(:last-child) {
        border-right: 1px solid $sbv-dark-blue;
    }
}

.state-buttons__button {
    display: inline-block;
    padding: rem-calc(6) rem-calc(16);
    flex: 1;
    line-height: 1.3;
    font-weight: 600;
    text-align: center;
    color: $white;
    background-color: $sbv-blue;
    cursor: pointer;

    &:hover {
        background-color: $sbv-dark-blue;
    }

    &.active {
        background-color: $sbv-dark-blue;
    }

    &.inactive {
        opacity: .5;
    }
}