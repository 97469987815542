.data-block {
    position: relative;
    border: 1px solid $sbv-light-grey;
    transition: border .2s ease-in-out;

    &.is-checked {
        border-color: $sbv-blue;
    }

    &.is-disabled {
        opacity: .5;
        pointer-events: none;
    }
}

.data-block__inner {
    padding: rem-calc(16);


    .switch {
        max-width: 100%;
        margin-bottom: rem-calc(16);

        .icon {
            font-size: rem-calc(18);
            vertical-align: middle;
            display: inline-block;
            margin-right: rem-calc(6);
        }
    }
}

.data-block__data {
    .input {
        padding-bottom: rem-calc(10);

        &:last-child {
            padding-bottom: 0;
        }
    }

    .message {
        margin-bottom: 0;
        font-size: rem-calc(20);
        opacity: .75;
    }
}

.data-block__placeholder-title {
    font-size: rem-calc(20);
    font-weight: bold;
}