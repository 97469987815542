.button,
.btn {
    display: block;
    padding: rem-calc(12);
    font-weight: 600;
    font-family: $body-font-family;
    background-color: $sbv-blue;
    color: $white;
    text-align: center;
    line-height: 1.3;
    min-width: rem-calc(100);
    box-shadow: 0 0 0 0 rgba($black, 0) inset;
    border: 1px solid $sbv-blue;
    transition: background .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out;
    cursor: pointer;

    &:hover:not(:disabled),
    &:active {
        background-color: $sbv-dark-blue;
        color: $white;
        border-color: $sbv-dark-blue;
        font-weight: 600;
    }

    &:active {
        box-shadow: 1px 1px 4px 0 rgba($black, .4) inset;
    }

    &:disabled,
    .disabled {
        opacity: .5;
        pointer-events: none;
    }

    @include breakpoint(medium) {
        padding: rem-calc(16);
        min-width: rem-calc(200);
    }
}

.btn--secondary {
    background-color: $white;
    color: $sbv-blue;
}

.btn--small {
    min-width: 0;
    padding: rem-calc(4) rem-calc(8);
    font-size: rem-calc(14);
    font-family: $body-font-family;

    > .icon {
        margin: 0 0 0 rem-calc(4) !important;   // sass-lint:disable-line no-important
        vertical-align: middle;
    }
}

.btn.center {
    margin: auto;
}

.btn--icon {
    padding: rem-calc(4) rem-calc(8);
    background-color: transparent;
    min-width: 0;
    color: $sbv-blue;
    border: 0;
    font-size: rem-calc(18);

    > .icon {
        vertical-align: middle;
    }
}

.button-group {
    overflow: hidden;
    display: flex;

    .button,
    .btn {
        flex: 1;
        margin-bottom: rem-calc(20);

        &:not(:last-child) {
            margin-right: rem-calc(16);
        }
    }

    @include breakpoint(medium) {
        display: block;

        .button,
        .btn {
            float: left;

            &:not(:last-child) {
                margin-right: rem-calc(20);
            }
        }
    }
}