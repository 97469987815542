.main-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: $global-width;
    background-color: $white;
    box-shadow: 0 17px 13px 0 rgba(0, 0, 0, .4);
    min-height: 100%;

    .main-content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
    }

    @include breakpoint(small only) {
        .sub-sidebar + .app-content {
            margin-top: rem-calc(52);
        }
    }

    @include breakpoint(medium) {
        height: 100%;
        overflow: hidden;

        .app-content {
            margin-left: rem-calc(40);
        }

        .sub-sidebar + .app-content {
            margin-left: 0;
        }
    }

    @include breakpoint(large) {
        .app-content {
            margin-left: rem-calc(50);
        }
    }
}