$sbv-blue: rgb(92, 144, 221);
$sbv-dark-blue: rgb(50, 100, 173);
$sbv-grey: rgb(86, 91, 94);
$sbv-light-grey: rgb(241, 241, 241);
$sbv-error-red: rgb(201, 47, 47);
$sbv-ok: rgb(40, 174, 136);

$sbv-yellow: $sbv-blue;
$sbv-yellow-dark: $sbv-dark-blue;
$sbv-green: $sbv-blue;
$sbv-dark-green: $sbv-dark-blue;
$red-error: $sbv-error-red;

$label-font: Verdana, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

$heading-font-family: $label-font;

$main-sidebar-width: 50px;
$main-sidebar-width-medium: 60px;
$main-sidebar-width-large: 140px;

$input-icon-primary-bg-color: $sbv-blue;
$input-icon-primary-bg-hover-color: $sbv-dark-blue;

$sbv-btn: $sbv-blue;
$sbv-btn-hover: $sbv-dark-blue;