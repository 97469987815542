$gitlab-link-color: rgb(92, 144, 221) !default;

.git-link {
    line-height: 28px;

    &:hover {
        &:before {
            opacity: .25;
        }
    }

    &:before {
        background-image: url('/static/assets/images/gitlab-logo.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        display: inline-block;
        fill: $gitlab-link-color;
        content: ' ';
        height: 28px;
        width: 28px;
        position: relative;
        top: 8px;
        margin-right: 10px;
    }

    &.git-link__small {
        display: block;
        padding: 0 .5rem;
        text-align: center;
    }
}