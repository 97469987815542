$white: "#fff" !default;

.main-nav {
    .export {
        .main-nav__link {
            color: $white;
        }
    }
    .reset {
        .main-nav__link {
            color: $white;
        }
    }
}