.pagination {
    @extend %list-reset;
    overflow: hidden;

    > li {
        float: left;
        margin-bottom: rem-calc(8);

        &:not(:last-child) {
            margin-right: rem-calc(4);
        }

        &.active {
            a {
                background-color: $sbv-dark-blue;
                color: $white;
            }
        }

        &.disabled {
            opacity: .5;
            pointer-events: none;
        }

        [aria-label='First'] {
            > span {
                font-family: sbv-datenbank;
                font-size: rem-calc(12);

                &::before,
                &::after {
                    content: '\38';
                    display: inline-block;
                    transform: rotate(90deg);
                }

                &::before {
                    margin-right: rem-calc(-2);
                }

                &::after {
                    margin-left: rem-calc(-2);
                }
            }
        }

        [aria-label='Previous'] {
            > span {
                font-family: sbv-datenbank;
                font-size: rem-calc(12);

                &::before {
                    content: '\38';
                    display: inline-block;
                    transform: rotate(90deg);
                }
            }
        }

        [aria-label='Next'] {
            > span {
                font-family: sbv-datenbank;
                font-size: rem-calc(12);

                &::before {
                    content: '\38';
                    display: inline-block;
                    transform: rotate(-90deg);
                }
            }
        }

        [aria-label='Last'] {
            > span {
                font-family: sbv-datenbank;
                font-size: rem-calc(12);

                &::before,
                &::after {
                    content: '\38';
                    display: inline-block;
                    transform: rotate(-90deg);
                }

                &::before {
                    margin-right: rem-calc(-2);
                }

                &::after {
                    margin-left: rem-calc(-2);
                }
            }
        }

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem-calc(38);
            height: rem-calc(38);
            background-color: $sbv-light-grey;
            font-size: rem-calc(14);
            color: $body-font-color;
            outline: none;
        }
    }

    .sr-only {
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }

    @include breakpoint(large) {
        li {
            > a {
                width: rem-calc(45);
                height: rem-calc(45);
            }
        }

        li:not(.disabled, .active) {
            a {
                &:hover {
                    background-color: rgba($sbv-blue, .4);
                }
            }
        }
    }
}