input,
input[type='text'],
input[type='email'],
input[type='date'],
input[type='tel'],
input[type='number'],
input[type='search'],
select,
textarea {
    appearance: none;
    padding: rem-calc(5) rem-calc(10);
    line-height: 1.3;
    font-family: $body-font-family;
    font-size: rem-calc(16);
    font-weight: 600;
    color: $body-font-color;
    background-color: $white;
    border: 1px solid rgba($black, .6);
    text-overflow: ellipsis;
    border-radius: 0;

    &::placeholder {
        color: rgba($body-font-color, .6);
    }
}

input[type='text']:disabled {
    opacity: 0.5;
}

textarea {
    display: block;
    min-height: rem-calc(120);
}

// sass-lint:disable no-vendor-prefixes
select {
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
}
// sass-lint:enable no-vendor-prefixes