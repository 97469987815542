.app-content {
    position: relative;
    flex: 1;
    padding: rem-calc(36) rem-calc(0) rem-calc(20) rem-calc(8);
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;

    @include breakpoint(medium) {
        padding: rem-calc(110) rem-calc(5) rem-calc(30) rem-calc(9);

        // sass-lint:disable no-vendor-prefixes
        -webkit-overflow-scrolling: touch;
    }

    @include breakpoint(xlarge) {
        padding-top: rem-calc(120);
    }

    // Ugly hack to preserve space at bottom. Of course because of IE11/EDGE...
    &::after {
        content: '';
        display: block;
        height: rem-calc(20);
    }
}