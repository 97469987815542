.sub-nav {
    @extend %list-reset;
}

.sub-nav__item {
    display: block;
    margin-bottom: rem-calc(10);
}

.sub-nav__link {
    display: block;
    padding: rem-calc(8) rem-calc(16) rem-calc(8) 0;
    color: $body-font-color;
    font-weight: bold;
    line-height: 1;
    font-size: rem-calc(14);
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        color: $sbv-blue;
    }

    &.is-active {
        color: $sbv-dark-blue;
    }

    @include breakpoint(medium) {
        padding: rem-calc(8) rem-calc(16);

        &.is-active {
            border: 1px solid $sbv-dark-blue;
        }
    }

    @include breakpoint(large) {
        font-size: rem-calc(16);
    }
}