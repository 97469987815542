.comp-dashboard {
    .checkbox__label--inner {
        max-width: rem-calc(217);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table {
        .icon-link {
            &.assigned {
                text-align: left;
            }
        }
    }

    h2 {
        margin-top: rem-calc(135);
        margin-bottom: rem-calc(5);
    }
}