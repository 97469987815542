.table {
    font-size: rem-calc(12);

    thead {
        font-weight: 600;
        background-color: $sbv-light-grey;
    }

    td,
    th {
        text-align: left;
        vertical-align: middle;
        white-space: nowrap;

        &:not(:last-child) {
            border-right: 1px solid rgba($sbv-grey, .1);
        }
    }

    tbody {
        tr {
            transition: background .1s ease-in-out;

            &:nth-child(2n) {
                background-color: rgba($sbv-light-grey, .4);
            }
        }
    }

    th {
        position: relative;
        padding: rem-calc(10) rem-calc(26) rem-calc(10) rem-calc(8);

        // overwrite widths set by react component
        width: auto !important; // sass-lint:disable-line no-important

        .sort-icon {
            position: absolute;
            top: 50%;
            right: rem-calc(8);
            transform: translateY(-50%);
            font-family: sbv-datenbank;
            font-size: rem-calc(12);
            cursor: pointer;
        }

        .sort-none {
            &::before {
                content: '\39';
            }
        }

        .sort-ascending {
            &::before {
                content: '\37';
            }
        }

        .sort-descending {
            &::before {
                content: '\38';
            }
        }
    }

    td {
        padding: rem-calc(10) rem-calc(8);

        > span {
            display: block;
        }
    }

    .sr-only {
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }

    .icon-link {
        display: block;
        padding: 0 rem-calc(8);
        text-align: center;
        font-size: rem-calc(20);
    }

    .state-display {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include breakpoint(medium) {
        font-size: rem-calc(14);
    }

    @include breakpoint(xlarge) {
        tbody {
            tr {
                &:hover {
                    // sass-lint:disable no-important
                    background-color: rgba($sbv-blue, .4) !important;
                    // sass-lint:enable no-important
                }
            }
        }
    }
}

// This hides the default 'no data' message from the component.
// Because we want custom styles and messages we'll handle it differently.
.table--no-data {
    > tbody {
        > tr {
            > td {
                display: none;
            }
        }
    }
}