html,
body {
    height: 100%;
}

body {
    background-color: rgba($black, .1);
}

hr {
    margin: rem-calc(40) 0;

}

// Typography
p {
    margin: 0 0 rem-calc(20);
    line-height: 1.31;

    &.large {
        font-size: rem-calc(20);
    }
}

a {
    color: $sbv-blue;
    text-decoration: none;
    font-weight: bold;
    transition: color .2s ease-in-out;

    &:hover,
    &:active {
        color: $sbv-dark-blue;
        font-weight: bold;
    }
}

h1 {
    @extend %h1-base;

    margin: 0 0 1.5em;
}

h2 {
    @extend %h2-base;

    margin: 0 0 1.1em;
}

h3 {
    @extend %h3-base;

    margin: 0 0 1em;
}

h4,
h5,
h6 {
    @extend %h4-base;

    margin: 0 0 1.1em;
}

// Lists
ul {
    padding-left: 1em;
}

ol {
    counter-reset: li;
    list-style: none;
    padding-left: 0;

    > li {
        position: relative;
        padding-left: 1em;

        &::before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            left: 0;
            color: $sbv-blue;
        }
    }
}

// Table
table {
    margin: 0;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}