.main-nav {
    @extend %list-reset;

    display: flex;
    flex-direction: column;
    height: 100%;
}

.main-nav__item {
    border-top: 1px solid rgba(0, 0, 0, .25);
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: rem-calc(96);
    text-align: center;

    &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, .25);
    }
}

.main-nav__link {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: $sbv-blue;
    text-decoration: none;

    > [class^='icon-'] {
        line-height: 1;
        color: $white;
        font-size: rem-calc(32);
    }

    &.is-active {
        background-color: $sbv-dark-blue;
    }

    @include breakpoint(large) {
        &:hover {
            background-color: rgba($sbv-dark-blue, .8);
        }
    }
}
