.top-btn {
    position: fixed;
    display: block;
    top: rem-calc(30);
    left: calc(50% + #{rem-calc($main-sidebar-width / 2)});
    width: rem-calc(64);
    height: rem-calc(26);
    background-color: $white;
    border: 1px solid $sbv-dark-blue;
    opacity: 0;
    z-index: -1;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 1px 3px 0 rgba($black, .3);
    transform: translateY(50%) translateX(-50%);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out, z-index .01s linear .3s;

    &::before {
        content: '\37';
        font-family: sbv-datenbank;
        color: $sbv-dark-blue;
    }

    &.show {
        opacity: .5;
        z-index: 50;
        transform: translateY(0) translateX(-50%);
        transition: z-index .01s linear, opacity .2s ease-in-out .05s, transform .2s ease-in-out .05s;
    }

    @include breakpoint(medium) {
        top: rem-calc(26);
        left: calc(50% + #{rem-calc($main-sidebar-width-medium / 2)});
    }

    @include breakpoint(large) {
        display: none;
    }
}

.has-submenu {
    .top-btn {
        top: rem-calc(80);
    }
}