.switch {
    margin-bottom: rem-calc(26);
    width: 100%;
    max-width: rem-calc(260);

    input {
        position: absolute;
        visibility: hidden;
        opacity: 0;
    }

    input:checked + .switch__control-label {
        &::after {
            background-color: $sbv-blue;
        }

        &::before {
            right: rem-calc(2);
        }
    }
}

.switch__label {
    font-family: $label-font;
    font-size: rem-calc(12);
}

.switch__control-label {
    display: flex;
    position: relative;
    font-size: rem-calc(20);
    font-weight: bold;
    line-height: 1.1;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &::after {
        content: '';
        display: block;
        margin-left: rem-calc(24);
        min-width: rem-calc(48);
        width: rem-calc(48);
        height: rem-calc(28);
        background-color: $sbv-grey;
        border-radius: 48px;
        transition: background .2s ease-in-out;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        right: rem-calc(22);
        width: rem-calc(24);
        height: rem-calc(24);
        border-radius: 100%;
        background-color: $white;
        transform: translateY(-50%);
        transition: right .2s ease-in-out;
    }
}