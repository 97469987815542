// Override react-selectize styles
// sass-lint:disable force-element-nesting
.react-selectize.default.root-node {
    width: 100%;
    margin-bottom: rem-calc(16px);

    .react-selectize-control {
        line-height: 1.3;
        font-family: $body-font-family;
        background-color: $white;
        border: 1px solid rgba($black, .6);
        border-radius: 0;
    }

    .value-wrapper {
        margin: rem-calc(2) rem-calc(4) rem-calc(2) 0;

        &:last-child {
            margin-right: 0;
        }
    }

    .tag-item {
        position: relative;
        padding: rem-calc(6) rem-calc(18) rem-calc(6) rem-calc(6);
        font-size: rem-calc(16);
        color: $sbv-blue;
        border: 1px solid $sbv-blue;
    }

    .tag-item__remove {
        display: block;
        position: absolute;
        width: rem-calc(11);
        height: rem-calc(11);
        top: rem-calc(2);
        right: rem-calc(2);

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            width: rem-calc(8);
            height: rem-calc(2);
            top: 50%;
            left: 50%;
            background-color: $sbv-blue;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
// sass-lint:enable force-element-nesting
