%list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

%h1-base {
    font-size: rem-calc(36);
    font-weight: 600;
    line-height: 1.2;
}

%h2-base {
    font-size: rem-calc(24);
    font-weight: 600;
    line-height: 1.2;
}

%h3-base {
    font-size: rem-calc(20);
    font-weight: 600;
    line-height: 1.25;
}

%h4-base {
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 1.3;
}