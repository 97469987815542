$sbv-error-red: rgb(201, 47, 47) !default;
$sbv-ok: rgb(40, 174, 136) !default;

.checkbox {
    display: block;
    position: relative;
    margin-bottom: rem-calc(26);
    width: 100%;
    max-width: rem-calc(260);

    > input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }

    > input:checked + .checkbox__label {
        &::after {
            border-color: $sbv-ok;
        }

        &::before {
            border-color: $sbv-ok;
        }
    }
}

.checkbox__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &::after {
        content: '';
        display: inline-block;
        margin-left: rem-calc(24);
        min-width: rem-calc(28);
        width: rem-calc(28);
        height: rem-calc(28);
        border: 1px solid rgba($black, .6);
        vertical-align: middle;
        transition: border-color .2s ease-in-out, background .2s ease-in-out;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        margin-top: rem-calc(-2);
        right: rem-calc(5);
        width: rem-calc(16);
        height: rem-calc(8);
        border-bottom: 2px solid $white;
        border-left: 2px solid $white;
        background: none;
        transform: translateY(-50%) rotate(-50deg);
        transition: border-color .2s ease-in-out, background .2s ease-in-out;
    }
}