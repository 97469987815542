.comp-project-reset-container {

    button {
        &.reset {
            display: inline-block;
        }
    }

    .project-reset-section {
        margin-top: rem-calc(55);
    }
}