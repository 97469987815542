$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-font: 'Asap', Arial, serif !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-icon-primary-color: rgba(255,255,255,1) !default;
$input-icon-primary-bg-color: rgba(124,182,79,1) !default;
$input-icon-primary-bg-hover-color: rgba(76,134,32,1) !default;

.radio-group {
    margin: 0 0 rem-calc(24);

    &.radio-inline {
        display: flex;
        flex-flow: row wrap;
    }

    .radio {
        margin-right: rem-calc(40);
        margin-bottom: rem-calc(12);

        @include breakpoint(medium) {
            margin-right: rem-calc(75);
        }
    }
}