.comp-input-advanced-select {
    margin-bottom: rem-calc(15);

    .label-description > div {
        display: none;
    }

    .react-autosuggest__input {
        width: 100%;
    }
}