.sub-sidebar {
    position: fixed;
    left: rem-calc(50);
    right: 0;
    z-index: 100;

    .sub-nav-trigger {
        display: block;
        position: relative;
        padding: rem-calc(14) rem-calc(16);
        height: rem-calc(52);
        background-color: $sbv-dark-blue;
        color: $white;
        z-index: 90;
        font-weight: normal;

        .icon {
            position: absolute;
            top: 50%;
            right: rem-calc(16);
            transform: translateY(-50%);
            vertical-align: middle;
            transition: transform .2s ease-in-out;
        }

        &.is-open {
            .icon {
                transform: translateY(-50%) rotateX(180deg);
            }
        }
    }

    .sub-nav-wrapper {
        position: absolute;
        top: rem-calc(50);
        left: 0;
        width: 100%;
        padding: rem-calc(10) rem-calc(16);
        background-color: $white;
        opacity: 0;
        z-index: 80;
        transform: translateY(-100%);
        transition: transform .2s ease-in-out, opacity .1s ease-in-out .2s;

        .back-link {
            margin-bottom: rem-calc(32);
        }

        // open state
        &.is-open {
            transform: translateY(0);
            opacity: 1;
            transition: transform .2s ease-in-out, opacity .2s ease-in-out;
            box-shadow: 0 2px 2px 0 rgba($black, .2);
        }
    }

    @include breakpoint(medium) {
        width: rem-calc(200);
        min-width: rem-calc(200);
        position: static;
        padding-top: rem-calc(110);
        left: auto;
        right: auto;

        .sub-nav-trigger {
            display: none;
        }

        .sub-nav-wrapper {
            position: static;
            padding-top: 0;

            // Sub-nav is now always visible
            &,
            &.is-open {
                opacity: 1;
                transform: translateY(0);
                transition: none;
                box-shadow: none;
            }

            .back-link {
                margin-bottom: rem-calc(44);
            }
        }
    }

    @include breakpoint(large) {
        width: rem-calc(200);
        min-width: rem-calc(200);

        .sub-nav-wrapper {
            padding: 0 0 0 rem-calc(38);
        }
    }

    @include breakpoint(xlarge) {
        padding-top: rem-calc(120);
        width: rem-calc(250);
        min-width: rem-calc(250);

        .sub-nav-wrapper {
            padding: 0 0 0 rem-calc(78);
        }
    }
}