$input-mutted-color: rgb(153, 153, 153) !default;

.comp-vomhof {
    .inline-style {
        display: inline-block;
        margin-right: rem-calc(5);
    }

    .confirm-remove-offer-class {
        padding-top: 30%;
    }

    .section-select-product {
        @include breakpoint(medium) {
            max-width: rem-calc(555);
        }
    }

    .offer-remove-link {
        margin-top: 0.9rem;
        display: block;
        width: 40%;
        float: right;

        a {
            cursor: pointer;
            color: $input-mutted-color;

            &:after {
                background-color: $input-mutted-color;
            }
        }
    }
}
