.message-list-comp {
    .datalist {
        tr {
            &.unread {
                td {
                    font-weight: bold;
                }
            }
        }
    }

    .btn--icon {
        margin: 0 auto;
    }

    .btn--icon > .icon {
        margin: 0 auto !important;
    }
}
