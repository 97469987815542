.datalist {
    width: 100%;
    margin-bottom: rem-calc(48);
}

.datalist__table {
    margin-bottom: rem-calc(20);
    overflow-x: auto;
    // sass-lint:disable no-vendor-prefixes
    -webkit-overflow-scrolling: touch;
    // sass-lint:enable no-vendor-prefixes
}

.datalist__controls {
    margin: 0 rem-calc(-15);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.datalist__searchfilter,
.datalist__page-size {
    width: 100%;
    min-width: rem-calc(240);
    padding: 0 rem-calc(15);

    .input,
    .select {
        max-width: rem-calc(300);
        width: 100%;
    }

    @include breakpoint(480px) {
        width: 50%;
    }
}

.datalist__page-size {
    @include breakpoint(medium) {
        width: auto;
        min-width: rem-calc(200);
        flex: 0;
    }
}

.datalist__no-data,
.datalist__loader {
    padding: rem-calc(16) rem-calc(10);
}

.datalist__loader {
    .loader {
        margin-left: rem-calc(20);
        vertical-align: middle;
        display: inline-block;
    }
}