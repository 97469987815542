$white: rgb(255,255,255) !default;       // #ffffff
$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-radio-mutted-color: rgb(196,196,196) !default;      // #707070
$input-icon-primary-color: rgba(255,255,255,1) !default;
$input-icon-primary-bg-color: rgba(124,182,79,1) !default;
$input-icon-primary-bg-hover-color: rgba(76,134,32,1) !default;

.radio {
    display: table;
    position: relative;
    margin-bottom: rem-calc(10);

    > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    > label {
        display: flex;
        align-items: center;
        font-size: rem-calc(18);
        font-family: $heading-font-family;
        cursor: pointer;

        &::before {
            content: '';
            display: inline-block;
            margin-right: rem-calc(16);
            width: rem-calc(28);
            height: rem-calc(28);
            border: 1px solid $input-radio-mutted-color;
            border-radius: 100%;
            background-color: $white;
            box-shadow: 0 0 0 20px $white inset;
            transition: all .2s ease-in-out;
        }
    }

    > input:checked + label {

        &::before {
            background-color: $input-icon-primary-bg-color;
            box-shadow: 0 0 0 5px $white inset;
        }
    }

    &.disabled {

        > input:checked + label::before {
            background-color: $input-radio-mutted-color;
        }

        > label {
            cursor: default;
        }
    }
}