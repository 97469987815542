.file-card {
    display: flex;
    flex-direction: column;
    margin-bottom: map-get($grid-margin-gutters, small);
    height: calc(100% - #{map-get($grid-margin-gutters, small)});
    background-color: rgba($sbv-blue, .05);

    @include breakpoint(medium) {
        height: calc(100% - #{map-get($grid-margin-gutters, medium)});
        margin-bottom: map-get($grid-margin-gutters, medium);
    }
}

.file-card__header {
    flex: 0 0 auto;
    padding: rem-calc(12);
}

.file-card__body {
    flex: 1 1 auto;
    padding: 0 rem-calc(12) rem-calc(8);
}

.file-card__footer {
    padding: rem-calc(8) rem-calc(12);
}

.preview-image {
    position: relative;
    box-shadow: 0 2px 6px 0 rgba($black, .15);

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 100%;
    }
}

.preview-image__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        font-family: "object-fit: contain;"
    }
}

.file-info {
    font-size: rem-calc(12);
    word-break: break-all;
    line-height: 1.3;
}

.file-actions {
    @extend %list-reset;
    overflow: hidden;

    > li {
        float: left;
        margin-right: 0.5rem;
    }

    .file-action {
        appearance: none;
        display: block;
        cursor: pointer;
        transition: opacity .1s ease-in-out;
        outline: none;

        &:hover {
            opacity: .8;
        }

        .icon {
            display: block;
            font-size: rem-calc(14);
            color: $sbv-dark-blue;
        }
    }
}

.separator {
    padding: 0 rem-calc(12);

    > hr {
        margin: 0;
        border: 0;
        height: 1px;
        background-color: rgba($sbv-blue, .5);
    }
}