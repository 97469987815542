$sbv-ok: rgb(40, 174, 136) !default;
$red-error: rgb(201, 47, 47) !default;

.project-detail-link {
    .alert {
        border: 0;
        color: $red-error;

        &.center {
            text-align: center;
        }
    }

    .success {
        border: 0;
        color: $sbv-ok;

        &.center {
            text-align: center;
        }
    }
}

.create-button-no-data {
    text-align: right;

    &.center {
        text-align: center;
    }
}

.comp-detail-link-unauthorized {
    &.center {
        text-align: center;
    }
}
