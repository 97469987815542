.select {
    display: block;
    padding-bottom: rem-calc(24);
    font-size: rem-calc(12);
    font-family: $label-font;

    &.error {
        padding-bottom: rem-calc(4);
        color: $sbv-error-red;

        select {
            border-color: $sbv-error-red;
            color: $sbv-error-red;
        }

        .select__error {
            display: block;
        }
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
}

.select__wrapper {
    position: relative;
    display: block;
    margin-top: rem-calc(4);
    opacity: inherit;

    &::after {
        content: '\38';
        position: absolute;
        top: 50%;
        right: rem-calc(16);
        font-family: 'sbv-datenbank';
        transform: translateY(-50%);
        pointer-events: none;
    }
}

.select__error {
    display: none;
    margin-top: rem-calc(2);
    padding-left: rem-calc(10);
    font-family: $label-font;
    font-size: rem-calc(12);
    color: $sbv-error-red;
}