.input {
    display: block;
    padding-bottom: rem-calc(24);
    font-size: rem-calc(12);
    font-family: $label-font;

    input,
    textarea {
        width: 100%;
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.error {
        padding-bottom: rem-calc(4);
        color: $sbv-error-red;

        input,
        textarea {
            border-color: $sbv-error-red;
            color: $sbv-error-red;
        }

        .input__error {
            display: block;
        }
    }

    &.read-only {
        input,
        textarea {
            opacity: .74;
            pointer-events: none;
        }
    }

    &.show-state {
        .input__wrapper {
            &::after {
                display: block;
            }
        }
    }
}

.input__error {
    display: none;
    margin-top: rem-calc(2);
    padding-left: rem-calc(10);
    font-family: $label-font;
    font-size: rem-calc(12);
    color: $sbv-error-red;
}

.input__wrapper {
    position: relative;
    display: block;
    margin-top: rem-calc(4);
    opacity: inherit;

    &::after {
        content: attr(data-state);
        position: absolute;
        display: none;
        top: 50%;
        right: rem-calc(10);
        transform: translateY(-50%);
        color: $sbv-blue;
        text-transform: uppercase;
        font-size: rem-calc(12);
        font-weight: 400;
    }
}

.input__value {
    display: block;
    margin-top: rem-calc(2);
    font-family: $body-font-family;
    font-size: rem-calc(16);
    font-weight: 600;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input--read-only-mock {
    padding-bottom: rem-calc(18);
}

.input--search {
    .input__wrapper {
        input {
            padding-right: rem-calc(26);
        }

        &::before {
            content: '\73';
            font-family: sbv-datenbank;
            position: absolute;
            right: rem-calc(8);
            top: 50%;
            transform: translateY(-50%);
        }
    }
}