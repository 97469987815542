// sass-lint:disable no-important no-vendor-prefixes no-duplicate-properties

@charset 'UTF-8';

@font-face {
    font-family: 'proxima_nova';
    src: url('/static/assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('/static/assets/fonts/proximanova-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova';
    src: url('/static/assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('/static/assets/fonts/proximanova-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova';
    src: url('/static/assets/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('/static/assets/fonts/proximanova-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'sbv-datenbank';
    src: url('/static/assets/fonts/sbv-datenbank.eot');
    src: url('/static/assets/fonts/sbv-datenbank.eot?#iefix') format('embedded-opentype'),
    url('/static/assets/fonts/sbv-datenbank.ttf') format('truetype'),
    url('/static/assets/fonts/sbv-datenbank.woff') format('woff'),
    url('/static/assets/fonts/sbv-datenbank.svg#sbv-datenbank') format('svg');
    font-weight: normal;
    font-style: normal;

}

// Icomoon
@font-face {
    font-family: 'Icomoon';
    src: url('/static/assets/fonts/icomoon/icomoon-ultimate.woff2') format('woff2'),
    url('/static/assets/fonts/icomoon/icomoon-ultimate.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

[class*=' icon-'],
[class^='icon-'] {
    display: inline-block;
    line-height: 1;
}

[data-icon]::before {
    font-family: 'sbv-datenbank' !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']::before,
[class*=' icon-']::before {
    font-family: 'sbv-datenbank' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
    content: "\33";
  }
  .icon-checkbox-off:before {
    content: "\31";
  }
  .icon-checkbox-on:before {
    content: "\32";
  }
  .icon-close:before {
    content: "\34";
  }
  .icon-date:before {
    content: "\74";
  }
  .icon-detail:before {
    content: "\6c";
  }
  .icon-down:before {
    content: "\38";
  }
  .icon-edit:before {
    content: "\65";
  }
  .icon-farm:before {
    content: "\66";
  }
  .icon-help:before {
    content: "\69";
  }
  .icon-history:before {
    content: "\68";
  }
  .icon-home:before {
    content: "\64";
  }
  .icon-more:before {
    content: "\6d";
  }
  .icon-off:before {
    content: "\35";
  }
  .icon-on:before {
    content: "\36";
  }
  .icon-projects:before {
    content: "\70";
  }
  .icon-search-1:before {
    content: "\73";
  }
  .icon-setting:before {
    content: "\61";
  }
  .icon-up:before {
    content: "\37";
  }
  .icon-updown:before {
    content: "\39";
  }
  .icon-user:before {
    content: "\75";
  }
  .icon-warning:before {
    content: "\77";
  }
  .icon-login:before {
    content: "\78";
  }
  .icon-logout:before {
    content: "\7a";
  }
  .icon-reset:before {
    content: "\72";
  }
  .icon-delete:before {
    content: "\62";
  }
  .icon-copy:before {
    content: "\63";
  }
  .icon-export:before {
    content: "\67";
  }
  .icon-reset-project:before {
    content: "\6a";
  }  

// sass-lint:enable no-important no-vendor-prefixes no-duplicate-properties