.data-select-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    .data-block {
        flex: 1;

        &:not(:last-child) {
            margin-bottom: rem-calc(16);
        }
    }

    @include breakpoint(medium) {
        flex-direction: row;

        .data-block {
            &:first-child {
                margin-right: rem-calc(15);
            }

            &:last-child {
                margin-left: rem-calc(15);
            }

            &:not(:last-child) {
                margin-bottom: rem-calc(0);
            }
        }
    }
}