.upload-modal {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;

    &.is-open {
        opacity: 1;
        z-index: 1000;
        pointer-events: auto;
    }

    .upload-modal__content {
        position: absolute;
        margin: 0 auto;
        top: rem-calc(10);
        left: rem-calc(10);
        right: rem-calc(10);
        bottom: rem-calc(10);
        max-width: rem-calc(1200);

        @include breakpoint(large) {
            top: rem-calc(20);
            left: rem-calc(20);
            right: rem-calc(20);
            bottom: rem-calc(20);
        }
    }
}

.upload-modal-is-open {
    overflow: hidden;

    .app-content {
        overflow: hidden;
    }
}

.upload-modal__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .2);
    z-index: 1001;
}

.upload-modal__content {
    position: relative;
    background-color: $white;
    z-index: 1002;

    &.is-uploading::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($white, .85);
        z-index: 1;
    }

    .progress {
        position: absolute;
        z-index: 10;
        width: 100%;
        max-width: rem-calc(520);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .error-message {
        color: $sbv-error-red;
    }
}

.upload-modal__scrollable {
    position: absolute;
    top: rem-calc(40);
    left: rem-calc(30);
    right: rem-calc(30);
    bottom: rem-calc(40);
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
}

.dropzone {
    position: relative;
    padding: rem-calc(10);
    margin-bottom: rem-calc(32);
    width: 100%;
    height: 20vh;
    border: 2px dashed $sbv-grey;
    background-color: transparent;
    transition: border .2s ease-in-out, background-color .2s ease-in-out;
    cursor: pointer;

    @include breakpoint(medium) {
        min-height: rem-calc(128);
        max-height: rem-calc(240);
    }
}

.dropzone__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dropzone__caption {
    display: block;
    margin: 0 auto rem-calc(8);
    font-size: rem-calc(18);
    font-weight: bold;
    text-align: center;
    line-height: 1.2;

    @include breakpoint(large) {
        margin-bottom: rem-calc(16);
    }
}

.dropzone__caption-icon {
    display: block;
    margin: 0 auto;
    width: rem-calc(36);
    height: rem-calc(36);

    > svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: $sbv-grey;
    }

    @include breakpoint(large) {
        width: rem-calc(56);
        height: rem-calc(56);
    }
}

.dropzone--active {
    border-color: $sbv-blue;
    background-color: rgba($sbv-blue, .06);
}

.cta-buttons {
    @extend %list-reset;

    > li {
        float: left;
    }

    > li:not(:last-child) {
        margin-right: rem-calc(16);
    }
}

.progress {
    display: none;
    padding: rem-calc(12);
    background-color: $white;
    box-shadow: 1px 1px 10px 0 rgba($black, .1);

    &.is-active {
        display: block;
    }

    .progress__value {
        display: block;
        font-weight: bold;
    }

    .progress__bar {
        width: 100%;
        padding: rem-calc(2);
        height: rem-calc(32);
        background-color: $sbv-light-grey;
        border: 1px solid rgba($sbv-grey, .2);
    }

    .progress__progress {
        height: 100%;
        background-color: $sbv-blue;
    }
}