$sbv-error-red: rgb(201, 47, 47) !default;
$sbv-ok: rgb(40, 174, 136) !default;

// sass-lint:disable no-important
.cf::before,
.cf::after {
    content: '';
    display: table;
}

.cf::after {
    clear: both;
}

.color-green {
    color: $sbv-ok;
}

.color-red {
    color: $sbv-error-red;
}

.tx-right {
    text-align: right !important;
}

.space-1 {
    margin-bottom: 1rem !important;
}

.space-2 {
    margin-bottom: 2rem !important;
}

.space-3 {
    margin-bottom: 3rem !important;
}

.space-4 {
    margin-bottom: 4rem !important;
}
// sass-lint:enable no-important