.comp-base-form {
    margin-bottom: rem-calc(48);

    .vomhof-add-select {
        margin-bottom: rem-calc(39px);
    }

    .form-title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $sbv-grey;

        .edit-link {
            display: block;
            margin-left: rem-calc(10);

            @include breakpoint(medium) {
                margin-left: rem-calc(14);
            }

            // cross-browser alignment fix
            .icon-edit {
                display: flex;
                padding: 4px 0;
            }
        }
    }

    .is-invalid-input:not(:focus) {
        background-color: $white;
    }
}