$autosuggest-item-height: 32px !default;

.advanced-select__wrapper {
    display: block;
    position: relative;
    color: $body-font-color;

    .react-select__placeholder {
        color: $body-font-color;
    }

    .react-select__indicator {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .react-select__clear-indicator {
        padding: 2px 12px;
        margin-right: 12px;

        svg {
            color: $body-font-color;
        }
    }

    .react-select__dropdown-indicator {
        display: none;
    }

    .react-select__control {
        position: relative;

        width: 100%;
        border: 1px solid rgba($black, .6);
        border-radius: 0;
        padding: 0;
        margin: 0;
        box-shadow: none;
        min-height: 32px;

        &:hover {
            border: 1px solid rgba($black, .6);
        }
    }

    .react-select__control--is-focused {
        .react-select__placeholder {
            display: none;
        }
    }

    .react-select__value-container {
        padding: 0 .5rem;
        line-height: 1.3;
        font-family: proxima_nova, Verdana, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: #000;
        border-radius: 0;
        background-color: #fff;
        text-overflow: ellipsis;
    }

    .react-select__input {
        input {
            line-height: 1rem;
        }
    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__menu {
        background: $white;
        margin: 0;
        box-shadow: none;
        border-radius: 0;
    }

    .react-select__menu-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid rgba($black, .6);
        border-top: 0;
    }

    .react-select__option {
        border-top: 1px solid rgba($black, .6);
        line-height: $autosuggest-item-height;
        height: $autosuggest-item-height;
        padding: 0 .625rem;
        cursor: pointer;
        background: none;
    }

    .react-select__option:first-child {
        border-top: 0;
        height: $autosuggest-item-height - 1px;
    }

    .react-select__option--is-focused,
    .react-select__option--is-focused:active,
    .react-select__option--is-selected,
    .react-select__option--is-selected:active {
        color: rgba($black, .6);
        background: none;
    }

}