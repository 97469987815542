$sbv-ok: rgb(40, 174, 136) !default;
$red-error: rgb(201, 47, 47) !default;

.callout {
    &.alert {
        background-color: #fff;
        color: $red-error;
        border: 0;
    }

    &.success {
        background-color: #fff;
        color: $sbv-ok;
        border: 0;
    }
}