.back-link {
    display: inline-block;
    padding: rem-calc(6) 0;
    color: $sbv-blue;
    font-size: rem-calc(14);
    font-weight: bold;
    line-height: 1;

    .icon {
        display: inline-block;
        margin: 0 rem-calc(6) 0 rem-calc(-4);
        transform: rotate(90deg);
        vertical-align: middle;

    }

    span {
        vertical-align: middle;
    }
}