.modal {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;

    &.is-open {
        opacity: 1;
        z-index: 1000;
        pointer-events: auto;
    }

    .modal__content {
        margin: 0 auto;
        top: 10%;
        width: 90%;

        @include breakpoint(medium) {
            width: 75%;
        }

        @include breakpoint(large) {
            width: auto;
            min-width: rem-calc(550);
            max-width: rem-calc(630);
        }
    }
}

.modal-is-open {
    overflow: hidden;

    .app-content {
        overflow: hidden;
    }
}

.modal__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .2);
    z-index: 1001;
}

.modal__content {
    position: relative;
    padding: rem-calc(40) rem-calc(30) 0;
    background-color: $white;
    z-index: 1002;
    max-height: 80%;
    overflow: auto;

    .button-group {
        margin-bottom: rem-calc(-20);
    }

    &::after {
        content: '';
        display: block;
        height: rem-calc(40);
    }
}

.edit-modal {
    &.modal {
        .modal__content {
            @include breakpoint(large) {
                max-width: rem-calc(780);
            }
        }
    }

    &.is-saving {
        pointer-events: none;

        .save-overlay {
            display: flex;
        }

        .modal__background {
            pointer-events: none;
        }

        .modal__content {
            overflow: hidden;
        }
    }

    .save-overlay {
        position: absolute;
        display: none;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        z-index: 10;

        .loader {
            display: inline-block;
            vertical-align: middle;
            margin-left: rem-calc(8);
        }
    }
}