.main-sidebar {
    min-width: rem-calc($main-sidebar-width);
    width: rem-calc($main-sidebar-width);
    min-height: 100%;
    background-color: $sbv-blue;
    overflow-y: auto;

    .logo {
        display: flex;
        height: rem-calc(88);
        padding: rem-calc(8);
        justify-content: center;
        align-items: center;

        > img {
            width: 100%;
        }
    }

    .nav-wrapper {
        height: calc(100% - #{rem-calc(88)});
    }

    .main-nav {
        max-height: rem-calc(624);
        min-height: rem-calc(400);
    }

    @include breakpoint(medium) {
        height: 100%;
        min-width: rem-calc($main-sidebar-width-medium);
        width: rem-calc($main-sidebar-width-medium);
    }

    @include breakpoint(large) {
        min-width: rem-calc($main-sidebar-width-large);
        width: rem-calc($main-sidebar-width-large);

        .logo {
            padding: rem-calc(12);

            > img {
                width: auto;
                height: 100%;
            }
        }

        .main-nav {
            max-height: rem-calc(679);
        }
    }
}
